<template>
    <div style="width:100%;display: flex;justify-content: center;line-height: 0;">
        <div class="container" :class="handleThisClass()">
        <img :src="placeSvg" alt="" style="width: 14px; opacity: 0.7" />
        <div class="font">{{ handleThisName() }}</div>
    </div>
    </div>
</template>
<script>
import {getNameByCode} from "@/utils"
export default {
    name: "PlaceShow",
    props: {
        name: {
            type: String,
            default: 'NB',
        },
    },
    data() {
        return {
            placeSvg: require("@/assets/img/tab/place.svg"),
        };
    },
    methods: {
        handleThisClass() {
            return this.name.toLowerCase() + "-place";
        },
        handleThisName() {
            return getNameByCode(this.name);
        },
    },
};
</script>
<style scoped>
.container {
    width: 60px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    justify-content: center;
    gap: 3px;
    padding: 2px 0;
    color: #e9f4ff;
}

.nb-place {
    border: 2px solid var(--NB-RED-BORDER);
    background-color: var(--NB-RED-FIRST);
    box-shadow: inset 4px 4px 8px var(--NB-RED-SECOND), inset -4px -4px 8px var(--NB-RED-THIRD);
}
.sh-place {
    border: 2px solid var(--SH-BLUE-BORDER);
    background: var(--SH-BLUE-FIRST);
    box-shadow: inset 4px 4px 8px var(--SH-BLUE-SECOND), inset -4px -4px 8px var(--SH-BLUE-THIRD);
}

.qd-place{
    border: 2px solid var(--QD-GREEN-BORDER);
    background: var(--QD-GREEN-FIRST);
    box-shadow: inset 4px 4px 8px var(--QD-GREEN-SECOND),
            inset -4px -4px 8px var(--QD-GREEN-THIRD);
}

.tj-place{
    border: 2px solid var(--TJ-YELLOW-BORDER);
    background: var(--TJ-YELLOW-FIRST);
    box-shadow: inset 4px 4px 8px var(--TJ-YELLOW-SECOND), inset -4px -4px 8px var(--TJ-YELLOW-THIRD);
}

.xm-place{
    border: 2px solid var(--XM-PURPLE-BORDER);
    background: var(--XM-PURPLE-FIRST);
    box-shadow: inset 4px 4px 8px var(--XM-PURPLE-SECOND), inset -4px -4px 8px var(--XM-PURPLE-THIRD);
}

.sz-place{
    border: 2px solid var(--SZ-ORANGE-BORDER);
    background:var(--SZ-ORANGE-FIRST);
    box-shadow: inset 4px 4px 8px var(--SZ-ORANGE-SECOND), inset -4px -4px 8px var(--SZ-ORANGE-THIRD);
}
.font {
    font-size: 12px;
    font-weight: bold;
    user-select: none;
}
</style>
